import React from "react";
import header from '../images/header.png';
import Fullsize from '../images/full.jpg';
import Half from '../images/half.jpg';
import Visibility from '../images/visibility.jpg';
import Pocket from '../images/pocket.jpg';
import Spotlight from '../images/spotlight.jpg';
import Land from '../images/landscape.jpg';

const Section7 = () => {
    return (

        <div className="container mt-5 pt-5 section7" id="adsSize">
            <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="row d-flex justify-content-center">
                        <h2 className="section7_heading" >FULL JACKET:</h2>
                        <p>The name Full Jacket page refers to the whole of a newspaper page which has about 17 * 12.5 Inch standard size. A Full Jacket of these dimensions brings the ad to a size of about 31-32 cm in width and 41-42 cm in length.</p>
                        <p>A full page ad occupies a complete corner of a newspaper page and is also referred to as a full jacket size advertisement. </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-5 image-aling">
                    <img src={Fullsize} className="section7_img" alt="fullsize_img" />
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-sm-12 col-md-6 col-lg-6  mb-5">
                    <div className="row d-flex justify-content-center">
                        <h2 className="section7_heading" >HALF JACKET:</h2>
                        <p>The name half Jacket page refers to the Half of a newspaper page which has about 8.5 * 12.5 Inch standard size. A Full Jacket of these dimensions brings the ad to a size of about 31-32 cm in width and 21-22 cm in length.
                        </p>
                        <p>A Half page ad occupies a complete corner of a newspaper page and is also referred to as a Half jacket size advertisement. </p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6  mb-5 image-aling">
                    <img src={Half} className="section7_img" alt="half_img" />
                </div>

            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6  mb-5">
                    <div className="row d-flex justify-content-center">
                        <h2 className="section7_heading" >POCKET ADS:</h2>
                        <p>  The name Pocket ads page refers to the 1/4th of a newspaper page which has about 6.5 * 8.5 Inch standard size. A pocket ads of these dimensions brings the ad to a size of about 15-16 cm in width and 21-22 cm in length.</p>
                        <p> A Pocket page ad occupies a complete half corner of a newspaper page and is also referred to as a Pocket ads size advertisement.</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6  mb-5 image-aling">

                    <img src={Pocket} className="section7_img" alt="pocket_img" />

                </div>
            </div>
            <div className="row d-flex justify-content-center  section7-row">
                <div className="col-sm-12 col-md-6 col-lg-6  mb-5 image-aling-start image-second">
                    <img src={Visibility} className="section7_img" alt="visibility_img" />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6  mb-5  content-first">
                    <div className="row d-flex justify-content-center">
                        <h2 className="section7_heading" >VISIBILITY ADS:</h2>
                        <p>The name Visibility ads page refers to the 1/3rd of width  of a newspaper page which has about  4.2 * 4 Inch standard size. A Visibility ads of these dimensions brings the ad to a size of about 10 -10.5 cm in width and 9 - 10 cm in length.</p>
                        <p>A Visibility page ad occupies a complete side corner of a newspaper page and is also referred to as a visibility ads size advertisement. </p>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center section7-row">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-5 image-aling-start image-second">
                    <img src={Land} width="100%" className="section7_img" alt="ads6_img" />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6  mb-5 content-first">
                    <div className="row d-flex justify-content-center">
                        <h2 className="section7_heading" >Landscape ADS:</h2>
                        <p>The name Landscape ads page refers to the full width page with slider ads of a newspaper page which has about  2 * 12.5 Inch standard size. A Visibility ads of these dimensions brings the ad to a size of about 12 -12.5 cm in width and 5 - 5 cm in length.</p>
                        <p>A Landscape page ad occupies a complete side corner of a newspaper page and is also referred to as a Landscape ads size advertisement. </p>
                    </div>
                </div>

            </div>

            <div className="row d-flex justify-content-center">
                <h3 className="section7_heading">Spotlight ads :</h3>
                <p>The name Spotlight ads page refers to the Top of a newspaper page which has about  2 * 4 Inch standard size. A Visibility ads of these dimensions brings the ad to a size of about 9.5 - 10 cm in width and 5 - 5 cm in length.</p>
                <p>A Spotlight ads page ad occupies a complete Top side corner of a newspaper page and is also referred to as a Spotlight ads size advertisement. </p>
            </div>
            <div className="row d-flex justify-content-center mb-5">
                <img src={header} alt="header_img" />
            </div>
        </div>




    )
}

export default Section7