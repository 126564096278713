import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import Fullsize from '../images/full jacket ads.jpg';
// import Half from '../images/half jacket ads.jpg';
// import Visibility from '../images/visibility ads.jpg';
// import Pocket from '../images/pocket ads.jpg';

import Fullsize from '../images/full.jpg';
import Half from '../images/half.jpg';
import Visibility from '../images/visibility.jpg';
import Pocket from '../images/pocket.jpg';
import Spotlight from '../images/spotlight.jpg';
import Land from '../images/landscape.jpg';



import { FaIndianRupeeSign } from "react-icons/fa6";
import Redtage from '../images/redTage.png';
import Yellowtage from '../images/yellowTage.png';
import { BiSolidOffer } from "react-icons/bi";


const Topchoice = () => {

    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const handleView = (src) => {
        setImageSrc(src);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };
    const handleBooking = () => {
        navigate("/Slot");
    };

    return (
        <div className="container mt-5 ">
            <div className="row d-flex justify-content-center">
                <h1 className="headings mb-5 mt-5">TABLOID ADS OPTION</h1>
                <div class="col-sm-12 col-md-12 col-lg-3 m-3 topchoice_main_div">
                    <img src={Redtage} className="red-tag" alt="tag" />
                    <img src={Fullsize} width="50%" alt="ads" />
                    <h4>Full jacket</h4>
                    <p class="expandable-text">The name Full Jacket page refers to the whole of a newspaper page which has about....</p>
                    <div class="popup-box">
                        <p class="expandable-text">The name Full Jacket page refers to the whole of a newspaper page which has about 17 * 12.5 Inch standard size. A Full Jacket of these dimensions brings the ad to a size of about 31-32 cm in width and 41-42 cm in length.
                            A full page ad occupies a complete corner of a newspaper page and is also referred to as a full jacket size advertisement.</p>
                    </div>
                    <hr />
                    <p style={{ float: 'left' }}><BiSolidOffer style={{ color: 'green' }} />AVV offer</p>
                    <p className="text-end"><FaIndianRupeeSign />42,500 per edition </p>
                    <div className="m-2 mt-3"> <button className="btn btn-primary mx-2 topchoice-btn" onClick={() => handleView(Fullsize)}>view</button>
                        <button className="btn btn-primary topchoice-booking-btn" onClick={handleBooking}>book Ads</button></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-3 m-3 topchoice_main_div">

                    <img src={Yellowtage} className="yellow-tag" alt="tage iamge"  />
                    <img src={Half} width="50%" alt="ads iamge" />
                    <h4>Half jacket</h4>
                    <p class="expandable-text">The name half Jacket page refers to the Half of a newspaper page which has about.... </p>
                    <div class="popup-box">
                        <p class="expandable-text">  <p>The name half Jacket page refers to the Half of a newspaper page which has about 8.5 * 12.5 Inch standard size. A Full Jacket of these dimensions brings the ad to a size of about 31-32 cm in width and 21-22 cm in length.
                            A Half page ad occupies a complete corner of a newspaper page and is also referred to as a Half jacket size advertisement.</p>
                        </p>
                    </div>
                    <hr />
                    <p style={{ float: 'left' }}><BiSolidOffer style={{ color: 'green' }} />AVV offer</p>
                    <p className="text-end"><FaIndianRupeeSign />22,500 per edition</p>
                    <div className="m-2 mt-3"> <button className="btn btn-primary mx-2 topchoice-btn" onClick={() => handleView(Half)}>view</button>
                        <button className="btn btn-primary topchoice-booking-btn" onClick={handleBooking}>book Ads</button></div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-3 m-3 topchoice_main_div">
                    {/* <p class="topchoice_flag">POPULAR</p> */}
                    <img src={Yellowtage} className="yellow-tag" alt="tage iamge"  />
                    <img src={Pocket} width="50%"  alt="ads iamge" />
                    <h4>Pocket ads</h4>
                    <p class="expandable-text">The name Pocket ads page refers to the 1/4th of a newspaper page which has about.... </p>
                    <div class="popup-box">
                        <p class="expandable-text">  <p>The name Pocket ads page refers to the 1/4th of a newspaper page which has about 6.5 * 8.5 Inch standard size. A pocket ads of these dimensions brings the ad to a size of about 15-16 cm in width and 21-22 cm in length.
                            A Pocket page ad occupies a complete half corner of a newspaper page and is also referred to as a Pocket ads size advertisement.</p>
                        </p>
                    </div>
                    <hr />
                    <p style={{ float: 'left' }}><BiSolidOffer style={{ color: 'green' }} />AVV offer</p>
                    <p className="text-end"><FaIndianRupeeSign />12,500 per edition </p>
                    <div className="m-2 mt-3"> <button className="btn btn-primary mx-2 topchoice-btn" onClick={() => handleView(Pocket)}>view</button>
                        <button className="btn btn-primary topchoice-booking-btn" onClick={handleBooking}>book Ads</button></div>
                </div>
            </div>

            <div className="row d-flex justify-content-center">

                <div className="col-sm-12 col-md-12 col-lg-3 m-3 topchoice_main_div">
                    {/* <p class="topchoice_flag">POPULAR</p> */}
                    <img src={Redtage} className="red-tag" alt="redrage" />
                    <img src={Visibility} width="50%" alt="ads2" />
                    <h4>Visibility ads </h4>
                    <p class="expandable-text">The name Visibility ads page refers to the 1/3rd of width of a newspaper page which.... </p>
                    <div class="popup-box">
                        <p class="expandable-text">The name Visibility ads page refers to the 1/3rd of width of a newspaper page which has about 4.2 * 4 Inch standard size. A Visibility ads of these dimensions brings the ad to a size of about 10 -10.5 cm in width and 9 - 10 cm in length.
                            A Visibility page ad occupies a complete side corner of a newspaper page and is also referred to as a visibility ads size advertisement.</p>
                    </div>
                    <hr />
                    <p style={{ float: 'left' }}><BiSolidOffer style={{ color: 'green' }} />AVV offer</p>
                    <p className="text-end"><FaIndianRupeeSign />4,000 per edition</p>
                    <div className="m-2 mt-3"> <button className="btn btn-primary mx-2 topchoice-btn" onClick={() => handleView(Visibility)}>view</button>
                        <button className="btn btn-primary topchoice-booking-btn" onClick={handleBooking}>book Ads</button></div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-3 m-3 topchoice_main_div">
                    {/* <p class="topchoice_flag">POPULAR</p> */}
                    <img src={Redtage} className="red-tag" alt="tage" />
                    <img src={Land} width="50%"  alt="land"/>
                    <h4>Landscape ads</h4>
                    <p class="expandable-text">The name Visibility ads page refers to the 1/3rd of width of a newspaper page which.... </p>
                    <div class="popup-box">
                        <p class="expandable-text">The name Landscape ads page refers to the full width page with slider ads of a newspaper page which has about 2 * 12.5 Inch standard size. A Visibility ads of these dimensions brings the ad to a size of about 12 -12.5 cm in width and 5 - 5 cm in length.
                            A Landscape page ad occupies a complete side corner of a newspaper page and is also referred to as a Landscape ads size advertisement.
                        </p>
                    </div>
                    <hr />
                    <p style={{ float: 'left' }}><BiSolidOffer style={{ color: 'green' }} />AVV offer</p>
                    <p className="text-end"><FaIndianRupeeSign />3500 per edition </p>
                    <div className="m-2 mt-3"> <button className="btn btn-primary mx-2 topchoice-btn" onClick={() => handleView(Land)}>view</button>
                        <button className="btn btn-primary topchoice-booking-btn" onClick={handleBooking}>book Ads</button></div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-3 m-3 topchoice_main_div">
                    {/* <p class="topchoice_flag">POPULAR</p> */}
                    <img src={Yellowtage} className="yellow-tag" alt="tage" />
                    <img src={Spotlight} width="50%" alt="spotlight" />
                    <h4>Spotlight ads</h4>
                    <p class="expandable-text"> The name Spotlight ads page refers to the Top of a newspaper page which has about.... </p>
                    <div class="popup-box">
                        <p class="expandable-text"> The name Spotlight ads page refers to the Top of a newspaper page which has about 2 * 4 Inch standard size. A Visibility ads of these dimensions brings the ad to a size of about 9.5 - 10 cm in width and 5 - 5 cm in length.
                            A Spotlight ads page ad occupies a complete Top side corner of a newspaper page and is also referred to as a Spotlight ads size advertisement
                        </p>
                    </div>
                    <hr />
                    <p style={{ float: 'left' }}><BiSolidOffer style={{ color: 'green' }} />AVV offer</p>
                    <p className="text-end"><FaIndianRupeeSign />3500 per edition</p>
                    <div className="m-2 mt-3"> <button className="btn btn-primary mx-2 topchoice-btn" onClick={() => handleView(Spotlight)}>view</button>
                        <button className="btn btn-primary topchoice-booking-btn" onClick={handleBooking}>book Ads</button></div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose} dialogClassName="modal-responsive">
                <Modal.Header closeButton>
                    <Modal.Title>View Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={imageSrc} alt="Ad" style={{ width: "100%" }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="view-btn" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Topchoice