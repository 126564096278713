import React from "react";
import Screen from '../images/section1.png'
// import Dimage from '../images/paper 1.jpg';

const Section1 = () => {
    return (
        <div className="container mt-5 section1">
            <h1 className="headings mt-5 text-center text-uppercase"> gandhinagar visibility ventures </h1>
            <p className="text-center" style={{ color: "#0C0181" }}>360 Degree services in media</p>

        <div className="col-12">
            <img src={Screen} width="100%" height='auto' alt="screen"/>
        </div>

            {/* <div className="container text-center mt-5">
                <div className="row d-flex justify-content-center pb-5">
                    <div className="col-4 containe1 pb-5">
                        <div className="p-4 pb-5">
                           
                                <h3 className="section1_heading">Reliable</h3>
                                <p>Elevate your advertising strategy with our reliable paper insertion services. Directly reach your target audience through high-impact, tangible ads placed in popular tabloids. Our precision targeting and proven effectiveness make us your trusted partner for cost-efficient and impactful marketing solutions.</p>
                       
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                    <div className="col-4 containe2">
                        <div className="p-4">
                            
                                <h3 className="section1_heading">Young</h3>
                                <p>We encourage every team member to share ideas and feedback openly thereby building strong relationships built on Trust and Faith.</p>
                           
                        </div>
                    </div>
                </div>
            
               
                    <div className="col-md-5 text-center image_contain">
                          <img src={Dimage} alt="paper"/>
                    </div>
                   
            
                  
              
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-4 containe3">
                    <div className="p-4">
                                <h3 className="section1_heading">Innovative</h3>
                                <p>We blend traditional reach with cutting-edge targeting techniques, delivering your message straight into the hands of your audience in a format they can't overlook. Our service is the bridge between classic charm and modern precision, ensuring your brand not only stands out but also resonates deeply. Transform your advertising strategy with our unique and impactful paper insertion solutions.</p>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                    <div className="col-4  containe4">
                    <div className="p-4">
                                <h3 className="section1_heading">Optimistic</h3>
                                <p>It's an optimistic twist on traditional advertising, promising to not only capture attention but also to create memorable impressions. Embrace the power of paper insertions and watch your brand's message spread with positivity and precision. Join us in rekindling the love for tangible ads and see your marketing goals flourish!</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}


export default Section1