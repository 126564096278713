import React, { useState } from "react";
import Group1 from '../images/Group 21.png'
import Group2 from '../images/Group 22.png'
import Group3 from '../images/Group 23.png'
import Group4 from '../images/Group 17.png'
import Modal from 'react-bootstrap/Modal';
import Dimage from '../images/paper 1.jpg';

import Fullsize from '../images/full.jpg';
import Half from '../images/half.jpg';
import Visibility from '../images/visibility.jpg';
import Pocket from '../images/pocket.jpg';
// import Spotlight from '../images/spotlight.jpg';
// import Land from '../images/landscape.jpg';

const Home = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  return (
    <div className="container-fluid home m-0" id="home">

      <div className="row d-flex justify-content-center  mt-5">
        <div className="col-12 text-center">
          <h6 className="home-heading">NEXT GENERATION SELF-SERVE</h6>
          <h6 className="home-heading">TABLOID AD PLATFORM</h6>
          <a className="btn home-btn mt-md-5 mb-5" href="/Slot">Book Ads</a>
        </div>
      </div>
      <div className="row d-flex justify-content-right popUp-three-btn">
        {show1 ? (
          <button className=" popUp-three-btn1" >
            <img src={Group3} alt="Group3" onClick={handleClose1} />
          </button>
        ) : (
          <button className=" popUp-three-btn1" >
            <img src={Group1} alt="Group1" onClick={handleShow1} />
          </button>
        )}
        {show2 ? (
          <button className="popUp-three-btn1" >
            <img src={Group3} alt="Group3" onClick={handleClose2} />
          </button>
        ) : (
          <button className="popUp-three-btn2" ><img src={Group2} onClick={handleShow2} alt="Group2" /></button>
        )}
        {show3 ? (
          <button className=" popUp-three-btn1" >
            <img src={Group3} alt="Group3" onClick={handleClose3} />
          </button>
        ) : (
          <button className="popUp-three-btn3" ><img src={Group4} onClick={handleShow3} alt="Group4" /></button>
        )}



      </div>

      <div className="row d-flex justify-content-center  text-uppercase">
        <div className="col-4 col-md-4 col-lg-2 m-2 home-contain">
          <p className="home-contain-first-price" style={{ marginBottom: '0rem' }}> 25000 </p>
          <p className="text-center" style={{ marginBottom: '0rem', fontSize: '15px' }}>Physical copy</p>
        </div>
        <div className="col-1 d-lg-none col-1-border" >
          <div className="border_col"></div>
        </div>
        <div className="col-4 col-md-4 col-lg-2  m-2  home-contain">
          <p className="home-contain-first-price" >4.5</p>
          <p className="home-contain_p3 " style={{ marginBottom: '0rem' }}>  Years Digital experience</p>
        </div>

        <div className="row d-flex justify-content-center d-lg-none" style={{ width: '80%' }} >
          <div style={{ border: '2px solid white' }}></div>
        </div>

        <div className="col-4 col-md-4 col-lg-2  m-2 home-contain">
          <p className="home-contain-first-price" >25000+</p>
          <p className="home-contain_p3" style={{ marginBottom: '0rem' }}>  Daily view online and offline</p>
        </div>
        <div className="col-1 d-lg-none col-1-border" >
          <div className="border_col" style={{ marginTop: '10px' }}></div>
        </div>
        <div className="col-4 col-md-4 col-lg-2  m-2 home-contain">
          <p className="home-contain_p1"> New era of Tabloid ad</p>
        </div>
      </div>

      <div className="row text-uppercase home-last-contain p-2">

        <div className="col-8 col-sm-8 col-md-8">
          <p className="home-last-contain-p" style={{ textAlign: 'justify' }}>You want people to notice your business. You can greatly benefit from creating newspaper tabloid ads.Unleash the power of tabloid advertising and watch your business shine in the headlines.
            Grab attention with our irresistible offers! Check out our tabloid ad for exclusive deals</p>

        </div>
        <div className="col-4 col-md-4 text-center">
          <div className="d-inline-block">
            <p className="home_details p-2">sponsored on our page</p>
            <p className="home_details p-2" >benefit of spread partnership</p>
          </div>

        </div>
      </div>
      <Modal show={show1} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose1} className="home-modal">
        <Modal.Body>
          <h3 className="modal_heading mb-4">BRANDING BENEFIT WITH AVV</h3>
          <ul>
            <li>25000 Physical Copy in Times, Gujarat, Sandesh</li>
            <li>Facebook-AVV Posting</li>
            <li>Instagram-AVV Posting With Brand Promotion</li>
            <li>80k To 2 Lakh Digital Viewer</li>
            <li>A Grade Quality</li>
            <li>Free Design</li>
            <li>Online Booking System with 24*7 Support</li>
          </ul>
        </Modal.Body>
      </Modal>
      <Modal show={show2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose2} className="home-modal">

        <Modal.Body>
          <h3 className="modal_heading mb-4">ONLINE WITH AVV</h3>
          <div className="row d-flex justify-content-center">
            <div className="col-5 m-2 p-2 modal2-image-div">
              <div className="row">
                <div className="col-6">
                  <img src={Fullsize} className="modal2_img" width="100%" alt="page1" />

                </div>
                <div className="col-6">
                  <p>Page 1</p>
                  <p>1.8 lakh to 2lakh views</p>
                </div>
              </div>
            </div>
            <div className="col-5 m-2 p-2 modal2-image-div" >
              <div className="row">
                <div className="col-6">
                  <img src={Visibility} className="modal2_img" width="100%" alt="page2" />
                </div>
                <div className="col-6">
                  <p>Page 2</p>
                  <p>80k to 1.20 lakh views</p>
                </div>
              </div>
            </div>
            <div className="col-8 m-2 p-2  modal2-image-div" >
              <div className="row">
                <div className="col-4">
                  <img src={Half} className="modal2_img" width="100%" alt="page3"/>
                </div>

                <div className="col-4">
                  <img src={Pocket} className="modal2_img" width="100%" alt="page4"/>
                </div>
                <div className="col-4">
                  <p>Page 3 & 4</p>
                  <p>1.20 Lakh to 1.60 Lakh Views</p>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
      <Modal show={show3}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose3} className="home-modal">

        <Modal.Body>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h3 className="modal_heading mb-4">OFFLINE WITH AVV</h3>
                <img src={Dimage} className="img-fluid" style={{ width: '50%' }} alt="Dimage" />
                <ul className="modal-li">
                  <li>25000 Physical Copies in Times, Gujarat, Sandesh</li>
                  <li>80k to 2 Lakh Digital Viewers</li>
                  <li>A Grade Quality</li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>


  )
}

export default Home