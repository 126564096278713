import React, { useState } from "react";
// import InterviewImage from '../images/interview.jpeg'
import axios from 'axios';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const baseurl = process.env.REACT_APP_BASE_URL;
const inquiryEndpoint = process.env.REACT_APP_INQUIRY_ENDPOINT;
const otpEndpoint = process.env.REACT_APP_VERIFY_OTP_ENDPOINT;

const Inquiry = () => {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [inquiry, setInquiry] = useState('');
    const [inquiryError, setInquiryError] = useState('');
    const [otp, setOtp] = useState(0);
    const [otpError, setOtpError] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);


    const handleMobileKeyPress = (e) => {
        // Check if the current value length is already 10
        if (mobile.trim().length === 10) {
            e.preventDefault();
        }
    };


    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }
    const validateForm = () => {
        let valid = true;
        const mobilePattern = /^\d{10}$/;
        if (!name) {
            setNameError('Name is required');
            valid = false;
        } else {
            setNameError('');
        }

        if (!mobile) {
            setMobileError('Mobile number is required');
            valid = false;
        } else if (!mobilePattern.test(mobile)) {
            setMobileError('Invalid mobile number');
            valid = false;
        } else if (/(\d)\1{9}/.test(mobile)) {
            setMobileError('Mobile number cannot contain repeating digits');
            valid = false;
        } else {
            setMobileError('');
        }

        if (!email) {
            setEmailError('Email is required');
            valid = false;
        } else {
            setEmailError('');
        }
        if (!inquiry) {
            setInquiryError('Inquiry is required');
            valid = false;
        } else {
            setInquiryError('');
        }
        return valid;
    }
    const handleInquiry = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        const url = window.location.href;
        const wordToCheck = "gandhinagar";
        let cityId;

        if (url.includes(wordToCheck)) {
             cityId = 2
            console.log(wordToCheck + "    " + "yes")
        } else {
             cityId = 2
            console.log(wordToCheck + "  " + "not availabe")
        }


        try {
            const Data = {
                name: name,
                email: email,
                mobile: mobile,
                inquiry: inquiry,
                cityId:cityId
            }
            const result = await axios.post(`${baseurl}/${inquiryEndpoint}`, Data, config)
            if (result.data.status === true) {
                const delayTime = 1000;
                setTimeout(() => {
                    setShowOtpModal(true);
                }, delayTime);
                //    setShowOtpModal(true);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong! Please try again later.',
                });
            }
        } catch (error) {
            console.error('Fetching error:-', error)
        }
    }


    const validateOtp = () => {
        let valid = true;
        if (!otp) {
            setOtpError('OTP is required');
            valid = false;
        } else {
            setOtpError('');
        }
        return valid;
    }

    const handleOtpVerify = async (e) => {
        e.preventDefault();
        if (!validateOtp()) {
            return;
        }

        try {
            const Data = {
                otp: otp
            }
            const result = await axios.post(`${baseurl}/${otpEndpoint}`, Data, config);

            if (result.data && result.data.status == true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Your inquiry has been submitted successfully!',
                });
                setShowOtpModal(false);
                window.location.reload()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: result.data.message || 'Something went wrong! Please try again later.',
                });
            }

        } catch (error) {
            console.error('Fetching error:-', error)
        }
    }
    const handleMobileChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        setMobile(numericValue);
    };

    return (

        <div className="container mt-5 inquiry" id="inquiry">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-12 inquiryForm">
                    <h1 className="inquiry_heading mb-5">INQUIRY</h1>

                    <form className="inquiry-form">
                        <div className="form-group row mt-3">
                            <div className="col-sm-12">
                                <input type="text" className="form-control" id="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                {nameError && <p className="text-light" style={{ fontSize: 'large' }}>{nameError}</p>}
                            </div>
                        </div>
                        <div className="form-group mt-3 ">
                            <div className="col-sm-12">
                                <input type="email" className="form-control" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {emailError && <p className="text-light" style={{ fontSize: 'large' }}>{emailError}</p>}
                            </div>
                        </div>

                        <div className="form-group  mt-3">
                            <div className="col-sm-12">
                                <input type="text" className="form-control" id="mobile" placeholder="Mobile no." onChange={handleMobileChange} onKeyPress={handleMobileKeyPress} />
                                {mobileError && <p className="text-light" style={{ fontSize: 'large' }}>{mobileError}</p>}
                            </div>
                        </div>

                        <div className="form-group  mt-3 mb-3">
                            <div className="col-sm-12">
                                <textarea rows="4" className="form-control" id="inquiry" value={inquiry} placeholder="Add Inquiry..." onChange={(e) => setInquiry(e.target.value)}>
                                </textarea>
                                {inquiryError && <p className="text-light" style={{ fontSize: 'large' }}>{inquiryError}</p>}
                            </div>
                        </div>
                        <div className="form-group mt-3 d-flex justify-content-center">
                            <button type="submit" className="col-6 btn inquiry-btn" onClick={handleInquiry}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>

            <Modal show={showOtpModal} className="otp-modal" onHide={() => setShowOtpModal(false)} size="sm" centered>
                <Modal.Header className="d-flex justify-content-center  text-center">
                    <Modal.Title className="inquiry-h2">Enter Email OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="number"
                        className="otpInput form-control"
                        placeholder="- - - - - -"
                        maxLength={6}
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                    />
                    {otpError && <p className="text-danger" style={{ fontSize: 'small' }}>{otpError}</p>}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center text-center">
                    <Button variant="primary" className="otp_btn" onClick={handleOtpVerify}>Verify OTP</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default Inquiry